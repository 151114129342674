.customize-menu{
  width: 400px;
  padding:24px;
  background: rgb(254, 254, 254);
  box-shadow: 0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12);
  border-radius:8px;
  position: absolute;
  top:unset;
  left:unset;
  transform: unset;
  cursor: move;

  .customize-header{
      margin:0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgb(232, 238, 250);
      padding-bottom:16px;
  }

  h2{
    font-family: "TT Commons";
    font-size: 3.0rem;
    font-weight: 700;
    color: #05112A;
    margin:0;
  }

  p{
    margin: 20px 0;
  }

  .container{
      display: flex;
      border-top: 1px solid #E8EEFA;
      padding: 18px 0;
      width:100%;
      align-items: center;
      justify-content: space-between;
      label {
        font-size: 1.6rem;
        font-weight: 400;
        white-space: nowrap;
        cursor: pointer;
      }
    &>div{
      display: flex;
      align-items: center;
      margin-right:60px;
      .toggle{
        margin-right:20px;
      }
      img{
        margin-right: 8px;
      }
    }
  }

  button.toggle.on{
    background-color: rgb(5, 17, 42);
  }
}

