@keyframes spinAnimation {
  0% { transform: rotate(0deg) }
 100% { transform: rotate(360deg) }
}

.search{
    &.drawer-panel{
        height: calc(100vh - 90px);
        overflow-y: hidden;
        position: relative;
        padding-left:16px;
      }
      .search-header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
      }
    .search-container{
        background: rgb(254, 254, 254);
        border: 1px solid rgb(232, 238, 250);
        color: rgb(5, 17, 42);
        width: 306px;
        height: 40px;
        padding: 12px 4px 12px 16px;
        display: flex;
        align-items: center;
        form{
          width: 100%;
          height:100%;
          display:flex;
          align-items: center;
          img{
            width:20px;
            height:20px;
            margin-top:6px;
          }
        }
        input{
            background: transparent;
            border:none;
            margin-left: 16px;
            font-size: 16px;
            margin-top: 2px;
            width: 100%;
        }
    }
    .search-header .filter-button{
        color: #6a7070;
        border: 1px solid rgb(232, 238, 250);
        background: rgb(254, 254, 254);
        width: 40px;
        height: 40px;
        border-radius: 24px;
        display:flex;
        align-items: center;
        justify-content: center;
        &[disabled]{
          opacity: 0.5;
        }
    }
    .empty, .no-results{
        height: 100%;
        position: relative;
        &:after{
          content: '';
          display: block;
          position: absolute;
          right: 0;
          width: 112%;
          height: 521px;
          background-repeat: no-repeat;
          background-position: center;
    
        }
        .title{
          font-size: 20px;
          font-weight:700;
          text-align: center;
          width: 80%;
          margin:40px auto 0 auto;
        }
        .p{
          text-align: center;
        //   width: 80%;
          margin:0 auto;
        }
      }

      .empty{
        &:after{
          bottom: 88px;
          background-image:url('../images/illustrations/search-empty.svg')
        }
      }
      .no-results{
        &:after{
          bottom: 0;
          background-image:url('../images/illustrations/search-no-results.svg')
        }
        button.primary{
          margin: 20px auto;
          display: block;
        }
      }
    .spinner{
      width:16px;
      height:16px;
      display:flex;
      align-items:center;
      justify-content:center;
      position:absolute;
      top:50%;
      left:50%;
      transform:translate(-50%, -50%);
      transform-origin:center;
      animation: 1500ms infinite linear spinAnimation;
    }

    .ai-response{
      padding: 0 16px;
      height: calc(100% - 220px);
      overflow-y: auto;
      padding-bottom: 100px;
      p{
        font-family: "TT Commons";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.4px;
      }
      button.secondary{
        margin: 0 auto;
      }
      h2{
        color: #000;
        font-family: "TT Commons";
        font-size: 18px;
        font-weight: 700;
        line-height: 24px; /* 133.333% */
        letter-spacing: 0.4px;
      }
      ul.learn-more{
        display:flex;
        align-items: center;
        justify-content: space-between;
        gap:16px;
      }
      ul li.article{
        background: #D9D9D9;
        height: 72px;
        flex:1;
        cursor:pointer;
      }
      ul li button{
          width:100%;
          display:flex;
          align-items:center;
          justify-content:space-between;
          padding: 6px 12px;
          &:hover{
            background-color: #eaeffa
          }
      }
    }
}