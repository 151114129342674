.account-menu{
  position: absolute;
  z-index: 3000;
  top: 50px;
  right: 30px;
  // overflow-x: hidden;
  width: 434px;
  box-shadow: 0px 2px 8px rgba(7, 15, 31, 0.1), 0px 4px 24px rgba(7, 15, 31, 0.1);
  background: white;
  border-radius: 16px;
  max-height:calc(100vh - 100px);
  overflow:auto;
}

.account-menu p{
  font-family: "Hind";
  font-size: 1.6rem;
  font-weight: 400;
  font-style: normal;
  color: #333333;
  line-height: 150%;
  margin: 0;
  margin-left:16px;
}

.account-menu .header{
    display:flex;
    justify-content: space-between;
    border-bottom: 6px solid rgba(155, 161, 172, 0.2);
    padding-top:40px;
    padding-bottom:16px;
}
.account-menu .header .title{
  font-family: "TT Commons";
  font-size: 4rem;
  font-weight: 700;
  font-style: normal;
  line-height: 100%;
  color: #1C222E;
}

.account-menu .header button{
  width:40px;
  height:40px;
  margin-right:16px;
}

.account-menu .header button img{
  pointer-events: none;
}

.account-menu ul{
    clear: both;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-left: 0;
}

.account-menu ul .divider{
  height:6px;
  width:100%;
  display: block;
  background-color:rgba(155, 161, 172, 0.2);
}

.account-menu ul>li{
    list-style-type:none;
    display: flex;
    align-items: flex-start;
    border-top:1px solid #E8EEFA;
    width: 110%;
    padding-left: 16px;
}

.account-menu ul>li button{
    /* @include p; */
    background:none;
    border:none;
    text-align: left;
    line-height:150%;
    padding: 16px 16px 16px 0;
    display: flex;
    align-items: center;
}

.account-menu ul>li button:before{
    content:'';
    display: block;
    float:left;
    width:16px;
    height:16px;
    margin-right:16px;
    background-image:url('../images/icons/chevron-right-mini.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    transition: all 300ms ease;
}

.account-menu ul>li button.expanded{
  transform: rotate(90deg);
  /* background-image:url('../images/icons/chevron-right-blue.svg'); */
}

.print{
  /* @include p; */
  width:100%;
  padding:16px;
  border-bottom: 6px solid rgba(155, 161, 172, 0.2);
  text-align:left;
}

.account-menu .get-app{
  display: flex;
  justify-content: space-between;
  padding:24px 16px;
  border-bottom: 6px solid rgba(155, 161, 172, 0.2);
}

.account-menu .get-app div{
    margin-right:24px;
}

.account-menu .get-app div p:first-of-type{
  font-weight:700;
}

.account-menu .primary{
  display: block;
  margin:20px auto;
  width:140px;
  background: #1C222E !important;
  height: 40px;
}
