.drawer{
    // height: 100vh;
    // height:calc(100vh - 64px);
    height:100%;
    overflow:hidden;
    width:416px;
    background-color: #F7F9FD;
    box-shadow: 1px -1px 10px rgba(213, 219, 232, 0.64);
    // border-radius: 8px 8px 0 0;
    border-radius: 0px 16px 16px 0px;
    z-index: 1999;
    position: relative;
}

.drawer.mini{
    width:102px
}
  
.drawer p{
    font-family: "Hind";
    font-size: 1.6rem;
    font-weight: 400;
    font-style: normal;
    color: #333333;
    line-height: 150%;
}

.drawer .tab{
    width: 56px;
    height: 56px;
    position: absolute;
    right:0;
    bottom:0;
    display:flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    cursor:pointer;
}

/* .drawer:not(.mini) .drawer-header{
    border-bottom: 1px solid rgba(155, 161, 172, 0.2);
} */

.drawer-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 20px 24px 24px 40px;
    padding:16px 16px 16px 32px;
    border-bottom: 1px solid rgba(155, 161, 172, 0.2);
}

.drawer.mini .drawer-header{
    padding: 0;
}

.drawer-header h2{
    font-family: "TT Commons";
    font-size: 30px;
    font-weight: 700;
    font-style: normal;
    min-height:60px;
    width: 100%;
    display:flex;
    align-items: center;
    color: #1C222E;
    margin:0;
  }

  .drawer-header .close, .drawer-header .expand-collapse{
    width: 46px;
    height: 46px;
    border-radius: 40px;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }

.drawer-header .close{
    background-image: url('../images/icons/close.svg');
}

.drawer.mini .drawer-header .close, .drawer.mini .drawer-header .expand-collapse{
    margin: 20px auto 20px 34px;
}

.drawer-header .expand-collapse.expand{
    background-image: url('../images/icons/expand-drawer.svg');
}
.drawer-header .expand-collapse.collapse{
    background-image: url('../images/icons/collapse-drawer.svg');
}


.toggle-tabs{
    display:flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(155, 161, 173, 0.25);
    height: 40px;
    padding: 3px;
    position: relative;
    margin-top:16px;
    button.active:after{
        content: '';
        display: block;
        height: 3px;
        background: linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
        border-radius: 15px 15px 0 0;
        position: absolute;
        bottom: -5px;
        left:0;
        width: 100%;
        transition: all 300ms ease;
        // left:18px;
    }
    // &.contents:after{
    //     left: 18px;
    // }
    // &.bookmarks:after{
    //     left:50%;
    //     transform: translate(-50%, 0);
    // }
    // &.recent:after{
    //     right:2px;
    // }
}

// .toggle-tabs

// .toggle-tabs.contents:after{
//     left: 2px;
// }

.toggle-tabs.bookmarks:after{
    // right:2px;
    /* transform: translate(-50%, 0); */
}
/* .toggle-tabs.recent:after{
    right:2px;
} */

.toggle-tabs button{
      font-family: "TT Commons";
      font-size: 18px;
      color:black;
      font-weight:500;
      padding:8px;
    //   padding-left:24px;
      flex-grow: 1;
      position: relative;
      background:none;
      border:none;
    //   width: 50%;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 40px;
      z-index: 1;
      white-space: nowrap;
}

.toggle-tabs button.active{
  font-weight:700;
}

.toggle-tabs button:focus{
    box-shadow: none;
}

.toc-list{
    margin-left:16px;
    height: calc(100vh - 160px);
    overflow-y: scroll;
    overflow-x: hidden;


.book-info{
    margin-top:16px;
    margin-left:16px;
    padding-top:16px;
    font-size:1.4rem;
    line-height:2rem;
    display: flex;
    align-items: center;
    margin-bottom:16px;
    width: 260px;
}

.book-info img{
    width: 50px;
    height: 64px;
    margin: 0 16px 0 0;
    float:left;
    border-radius:4px;
}

.book-info p{
    margin:0;
}

.book-info p:first-of-type{
    font-family: "TT Commons";
    font-size: 18px;
    line-height: 20px;
    font-weight: bold;
    font-style: normal;
    margin-top:8px;
    color:rgb(5, 17, 42);
}

.book-info p:last-of-type{
    color: inherit;
    font-family: "Hind",Helvetica,Verdana,sans-serif;
    font-size: 12px;
    font-weight: normal;
    line-height: 120%;
    padding-top: 8px
}

ul.chapters{
    margin-left: 16px;
    margin-top: 40px;
    clear: both;
}

ul.chapters>li div{
    list-style-type:none;
    display: flex;
    align-items: flex-start;
    border-top:1px solid #E8EEFA;
    width: 110%;
    margin-left: -16px;
    padding-left: 16px;
    position: relative;
    &.active:before{
        content: '';
        display: block;
        height:60%;
        width:4px;
        background: linear-gradient(135deg, #01CBB7 0%, #007899 60.42%, #005F79 100%);
        border-radius: 40px;
        position: absolute;
        left: 0;
        top:50%;
        transform: translate(0, -50%);
    }
}

ul.chapters>li div button{
    background:none;
    border:none;
    text-align: left;
    line-height:150%;
    padding: 16px 16px 16px 0;
    width: 80%;
    font-size:14px;
    &:hover{
        text-decoration: underline;
    }
  }

ul.chapters>li div button:first-of-type{
    width:16px;
    height:16px;
    margin-top:11px;
    margin-right:16px;
    background-image:url('../images/icons/chevron-blue copy.svg');
    transform: rotate(-90deg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
    // transition: all 300ms ease;
}

ul.chapters>li div button:first-of-type.expanded{
    transform: rotate(0deg);
    // background-image:url('../images/icons/chevron-right-mini-pink.svg');
    background-image:url('../images/icons/chevron-blue.svg');
}

ul.chapters>li div button p{
    pointer-events: none;
    margin: 0;
}

ul.sections{
    margin-left: 30px;
}

ul.sections li{
    position: relative;
}

ul.sections li.active:before{
    content: '';
    display: block;
    height:60%;
    width:4px;
    background: linear-gradient(135deg, #01CBB7 0%, #007899 60.42%, #005F79 100%);
    border-radius: 40px;
    position: absolute;
    left: 0;
    top:50%;
    transform: translate(0, -50%);
}

ul.sections li button {
    font-size:14px;
      background:none;
      border:none;
      text-align: left;
      line-height:150%;
      padding:16px 0 16px 16px;
      border-top:1px solid #E8EEFA;
    //   color:#4F5561;
      width: 95%;
    &:hover{
        text-decoration: underline;
    }
  }

ul.sections.hidden{
    display: none;
}
}

.bookmark-list{
    margin-top: 16px;
    height:100%;
}

.bookmarks-empty{
    height:100%;
    background-image: url('../images/illustrations/empty-bookmarks.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    p{
        text-align: center;
        margin: 0 auto;
    }
    .title{
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        width: 80%;
        margin: 40px auto 0 auto;
    }
}

.card{
    /* width: 100%; */
    padding:16px;
    background-color:white;
    font-size:1.3rem;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #E8EEFA;
    margin: 8px 0;
}

.bookmark-card{
margin: 0 16px 16px 16px;
display:flex;
align-items: center;
justify-content: space-between;
/* width: 89%; */
&:hover{
    box-shadow: 0px 2px 8px rgba(7, 15, 31, 0.1), 0px 4px 24px rgba(7, 15, 31, 0.1);
}
}

// .bookmark-card .title{
//       display: flex;
//       align-items: flex-start;
//       justify-content: space-between;
// }

.bookmark-card p{
    margin-right:8px;
    pointer-events: none;
    margin: 0;
    font-weight: bold;
    width:300px;
}

.bookmark-card img{
    pointer-events: none;
}
.bookmark-card>p{
    pointer-events: none;
}

.recent-list{
    margin-top:16px;
  }

.recent-card{
    margin: 0 16px 16px 16px;
    // width: 89%;
    &:hover{
        box-shadow: 0px 2px 8px rgba(7, 15, 31, 0.1), 0px 4px 24px rgba(7, 15, 31, 0.1);
    }
    p{
      @include p;
        margin: 0;
    }
    p:first-of-type{
      font-weight: 700;
    }
    .last{
      display: flex;
      align-items: center;
      margin-top:12px;
      img{
        width: 16px;
        height: 16px;
        margin-bottom: 3px;
      }
      p{
        font-size:1.4rem;
      }
      p:first-of-type{
        margin-left:8px;
        margin-right:4px;
        font-weight:700;
      }
    }
  }