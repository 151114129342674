//colors:
$dark: #222222;
$light: #FAFAFA;
$sepia: #F4F2E4;

$darkBlue: #0A3756;
$darkTeal: #005D83;
$lightTeal:#29DDA7;
$focusBlue:#1977D4;

$highlight-blue:  rgba(161, 235, 255, 0.25);
$highlight-blue-accent:#007899;
$highlight-green: rgba(142, 248, 238, 0.25);
$highlight-green-accent:#00796D;
$highlight-yellow: rgba(244, 217, 123, 0.25);
$highlight-yellow-accent: #EEA42E;
$highlight-pink: rgba(255, 167, 209, 0.25);
$highlight-pink-accent:#E5067C;

$grey0:#F1F1F1;
$grey1:#E6E6E6;
$grey2:#d9d9d9;
$grey3:#C7C7C7;
$grey4:#979797;
$grey5:#6A7070;
$grey6:#403F3F;

//new colors
$indigo: #1C222E;
$dark-grey: #4F5561;
$med-grey: #9BA1AD;
$light-grey: #E8EEFA;
$tinted-white:#F7F9FD;
$text-grey: #333333;
$white: #FEFEFE;
$accentMediumTeal: #00796D;

$primary-gradient: linear-gradient(135deg, #EE512E 0%, #DF006B 78.12%, #BD0071 100%);

//??new new colors
$new-indigo:#05112A;
$grey100: #fafafa;
$accentMediumBlue: #007899;

$plum: #6D0176;

$background-white: #FEFEFE;

$medium-blue: #007899;






//typography
@mixin h1-alt{
  font-family:'Hind';
  font-size:4rem;
  font-weight:300;
  font-style:normal;
  color:$indigo;
}

@mixin h1-catalog{
  font-family:'TT Commons';
  font-size:3.8rem;
  font-weight:700;
  font-style:normal;
  color:$indigo;
}

@mixin h1{
  font-family:'TT Commons';
  font-size:5rem;
  font-weight:700;
  font-style:normal;
  line-height: 100%;
  color:$indigo;
}

@mixin h2{
  font-family:'TT Commons';
  font-size:4rem;
  font-weight:700;
  font-style:normal;
  line-height: 100%;
  color:$indigo;
}

@mixin h3{
  font-family:'TT Commons';
  font-size:3rem;
  font-weight:700;
  font-style:normal;
  color:$indigo;
}

@mixin h4{
  font-family:'Hind';
  font-size:2.4rem;
  font-weight:400;
  font-style:normal;
  color:$indigo;
}

@mixin h5{
  font-family:'Hind';
  font-size:2rem;
  font-weight:400;
  font-style:normal;
  color:$indigo;
}

@mixin h6{
  font-family:'TT Commons';
  font-size:1.8rem;
  font-weight:700;
  font-style:normal;
  color:$indigo;
}

@mixin p{
  font-family:'Hind';
  font-size:1.6rem;
  font-weight:400;
  font-style:normal;
  color:$text-grey;
  line-height: 150%;
}

@mixin hideScrollBar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}



//  $xs: 0;
$sm: 400px;
$md: 800px;
$lg: 992px;
$xl: 1280px;
$xxl: 1440px;


