.toolbar{
    /* height:100vh; */
    height:160px;
    width: 86px;
    left: 0; 
    top:12px;
    position: absolute;
    z-index: 2000;
    /* overflow:hidden; */
    border-radius: 0px 16px 16px 0px;
    // filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.1));
    box-shadow: 1px 3px 10px 0px rgba(221, 227, 238, 0.64), 1px -3px 10px 0px rgba(221, 227, 238, 0.64);
    background-color: #F7F9FD;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.toolbar.expanded{
    // height: 100vh;
    // height:calc(100vh - 64px);
    height:calc(100vh - 90px);
}

.toolbar .divider{
    height:1px;
    width:100%;
    display: block;
    background-color: #E8EEFA;
    margin:10px 0;
    pointer-events: none;
}

.toolbar .tools-btn{
    width: 46px;
    height: 46px;
    border-radius: 40px;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    margin: 2px 0px;
    flex-shrink: 0;
    display:flex;
    align-items: center;
    justify-content: center;
}
// .toolbar .tools-btn:before{
    // content: '';
    // background-color: #1C222E;
    // width: 17px;
    // height: 17px;
    // position: absolute;
    // left: 60px;
    // top: 15px;
    // transform: rotate(45deg);
    // z-index: -1;
    // opacity:0;
    // transition: opacity 100ms ease;
//     display:none;
//   }
//   .toolbar .tools-btn:after{
    // content:"";
    // left: 65px;
    // top: 10px;
    // position: absolute;
    // background-color: #1C222E;
    // color: white;
    // font-family: 'Hind';
    // font-size: 13px;
    // font-weight:600;
    // padding: 4px 8px 2px 8px;
    // border-radius: 8px;
    // z-index: 2005;
    // opacity:0;
    // transform: scale(0.5);
    // transition: transform 100ms ease, opacity 100ms ease;
    // transform-origin: left top;
    // pointer-events: none;
//     display:none;
//   }
//   .toolbar .tools-btn:hover{
//     transform: scale(1.1);
//   }

//   .toolbar .tools-btn:hover:before{
//     // display: block;
//     opacity:1;
//   }

//   .toolbar .tools-btn:hover:after{
//     transform: scale(1);
//     opacity: 1;
//   }

.toolbar .tools-btn.home{
    background-image: url("../images/toolbar/home.svg");
}
// .toolbar .tools-btn.home:after{
//     content:"Home";
//   }
.toolbar .tools-btn.aiBtn{
    background-image: url('../images/bot-icon-mini.svg');
    background-size: 40px 40px;
}
.toolbar .tools-btn.tocBtn{
    background-image: url('../images/toolbar/toc.svg');
}
.toolbar .tools-btn.tocBtn.active{
    background-image: url('../images/toolbar/toc-white.svg'), linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
  }
// .toolbar .tools-btn.tocBtn:after{
//     content:"Table Of Contents";
//     width: 110px;
//   }
.toolbar .tools-btn.scrubber-btn{
    background-image: url('../images/toolbar/nav.svg');
}
.toolbar .tools-btn.scrubber-btn.active{
    background-image: url('../images/toolbar/nav-white.svg'), linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
}           
// .toolbar .tools-btn.scrubber-btn:after{
//     content:"Navigation";
// }
.toolbar .tools-btn.searchBtn{
    background-image: url('../images/toolbar/search.svg');
}
.toolbar .tools-btn.searchBtn.active{
    background-image: url('../images/toolbar/search-white.svg'), linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
  }
// .toolbar .tools-btn.searchBtn:after{
//     content:"Search";
// }
.toolbar .tools-btn.audioBtn{
    background-image: url('../images/toolbar/audio.svg');
}
.toolbar .tools-btn.audioBtn.active{
    background-image: url('../images/toolbar/audio-white.svg'), linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
}
// .toolbar .tools-btn.audioBtn:after{
//     content:"Audiobook";
// }
.toolbar .tools-btn.notebookBtn{
    background-image: url('../images/toolbar/notebook.svg');
}
.toolbar .tools-btn.notebookBtn.active{
    background-image: url('../images/toolbar/notebook-white.svg'), linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
}
// .toolbar .tools-btn.notebookBtn:after{
//     content:"Notebook";
// }
.toolbar .tools-btn.studyBtn{
    background-image: url('../images/toolbar/study.svg');
}
.toolbar .tools-btn.studyBtn.active{
    background-image: url('../images/toolbar/study-white.svg'), linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
}
// .toolbar .tools-btn.studyBtn:after{
//     content:"Flashcards";
// }
.toolbar .tools-btn.settingsBtn{
    background-image: url('../images/toolbar/settings.svg');
}
.toolbar .tools-btn.settingsBtn.active{
    background-image: url('../images/toolbar/settings-white.svg'), linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
}
// .toolbar .tools-btn.settingsBtn:after{
//     content:"Display Settings";
//     width: 100px;
// }
.toolbar .tools-btn.channelsBtn{
    // background-image: url('../images/toolbar/channels.svg');
    // background-image: url('../images/icons/channels-new.svg');
    // background-image: url('data:image/svg+xml,<svg class="MuiSvgIcon-root-14995 jss14878" focusable="false" viewBox="0 0 24 24" aria-hidden="true" width="24" height="24" fill="none" name="Channel"><path fill-rule="evenodd" clip-rule="evenodd" d="M21.2727 0H2.72727C1.22104 0 0 1.04661 0 2.33766V18.2338C0 19.5248 1.22104 20.5714 2.72727 20.5714H21.2727C22.779 20.5714 24 19.5248 24 18.2338V2.33766C24 1.04661 22.779 0 21.2727 0ZM2.05714 2.52429C2.05714 2.26607 2.27018 2.05714 2.57143 2.05714H21.4286C21.7298 2.05714 21.9429 2.26607 21.9429 2.52429V18.0471C21.9429 18.3054 21.7298 18.5143 21.4286 18.5143H2.57143C2.27018 18.5143 2.05714 18.3054 2.05714 18.0471V2.52429Z" fill="currentColor"></path><path d="M10.2857 18.5143H13.6607L13.7143 24H10.2857V18.5143Z" fill="currentColor"></path><path d="M5.14286 22.9714C5.14286 22.4034 5.60336 21.9429 6.17143 21.9429H17.8286C18.3966 21.9429 18.8571 22.4034 18.8571 22.9714C18.8571 23.5395 18.3966 24 17.8286 24H6.17143C5.60336 24 5.14286 23.5395 5.14286 22.9714Z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M8.57143 6.19981C8.57143 6.08189 8.60528 5.96635 8.66912 5.86641C8.85699 5.5723 9.25254 5.48314 9.55262 5.66727L15.8644 9.5402C15.9795 9.61084 16.0768 9.70617 16.1488 9.819C16.4118 10.2308 16.2845 10.7735 15.8644 11.0313L9.55262 14.9042C9.45065 14.9668 9.33277 15 9.21246 15C8.85843 15 8.57143 14.7187 8.57143 14.3717V6.19981Z" fill="currentColor"></path></svg>');
}
.toolbar .tools-btn.channelsBtn.active{
    // background-image: url('../images/toolbar/channels-white.svg'), linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
    background-image: linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
}
// .toolbar .tools-btn.channelsBtn:after{
//     content:"Channels - watch our videos";
//     width:180px;
// }
.toolbar .tools-btn.postsBtn{
    background-image: url('../images/icons/posts-dark.svg');
}
.toolbar .tools-btn.postsBtn.active{
    background-image: url('../images/icons/posts-light.svg'), linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
}
.toolbar .tools-btn.close{
    background-image: url('../images/icons/close.svg');
}
// .toolbar .tools-btn.close:after{
//     content:"Hide Menu Options";
//     width:160px;
// }
.toolbar .tools-btn.more{
    background-image: url('../images/toolbar/more-vertical.svg');
}
// .toolbar .tools-btn.more:after{
//     content:"Expand Menu Options";
//     width:160px;
// }
.toolbar .tools-btn.close,.toolbar .tools-btn.more{
    position: absolute;
    bottom: 8px;
    left:19px;
    @media (max-height: 640px) {
        bottom: -100vh;
    }
}


