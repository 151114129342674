@keyframes open {
  0% {
    width: 56px;
    height: 56px;
    border-radius:100%;
  }
  5% {
    border-radius: 12px;
  }
  90% {
    width:430px;
    height: calc(100vh - 98px);
    border-radius: 12px;
  }
  100% {
    width:400px;
    height: calc(100vh - 128px);
    border-radius: 12px;
  }
}

@keyframes close {
  0% {
    width:400px;
    height: calc(100vh - 128px);
    border-radius: 12px;
  }
  90%{
    border-radius: 28px;
  }
  100% {
    width: 56px;
    height: 56px;
    border-radius:100%;   
  }
}

@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@keyframes fadeInLoader {
  0% {
    opacity:0;
    height:0;
  }
  100% {
    opacity:1;
    height:32px;
  }
}

@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@keyframes bounce {
  0%,30%{
    transform:translate(0px);
    opacity:0.6;
  }
  50%  {
    transform:translate(0, -4px);
    opacity:1;
  }
  100% {
    transform:translate(0px);
    opacity:0.6;
  }
}

@keyframes spin {
  0%{
    transform: rotate(0deg);
  }
  100%{
    transform: rotate(360deg);
  }
}

*{
  box-sizing: border-box;
}

/* main{
  width:100%;
  height:100vh;
  font-family: sans-serif;
} */

.chatbot-container p{
  margin:0;
}

.chatbot-container button{
  border: none;
  background:transparent;
  cursor:pointer;
}

.chatbot-container button.primary{
  width: 88px;
  height: 40px;
  background: linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
  border-radius: 24px;
  color: #FEFEFE;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

.chatbot-container button.secondary{
  width: 130px;
  height: 40px;
  background: #FEFEFE;
  border: 1px solid #BCC1CB;
  border-radius: 24px;
  color: #020917;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
}

.chatbot-container{
  width: 56px;
  height: 56px;
  border-radius: 100%;
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  position: absolute;
  bottom:64px;
  right:24px;
  z-index: 2000;
  background-color:white;
}

.open {
  animation: open 800ms ease-in-out;
  animation-fill-mode: forwards;
}

.close {
  animation: close 800ms ease-in-out;
  animation-fill-mode: forwards;
}

.chatbot-container .open-button{
  width: 56px;
  height: 56px;
  border-radius: 100%;
}

.chatbot-container .chatbot-body{
  display:none;
  opacity:0;
}

.chatbot-container.open .chatbot-body{
  display:flex;
  animation: fadeIn 500ms ease-out;
  animation-fill-mode: forwards;
  animation-delay:800ms;
}

.chatbot-container .open-button{
  animation: fadeIn 300ms ease;
  animation-fill-mode:forwards;
}

.chatbot-container.open .open-button{
  animation: fadeOut 300ms ease;
  animation-fill-mode:forwards;
}

.chatbot-body{
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.chatbot-header{
  width:100%;
  height:96px;
  padding:28px;
  display:flex;
  justify-content:space-between;
  align-items:center;
}

.chatbot-header p{
  margin: 0;
  font-size: 32px;
  font-family: "TT Commons";
  font-weight: 700;
  color: rgb(2, 9, 23);
  display:flex;
  align-items: center;
  gap:4px;
}

.chatbot-header p span{
  color: #FEFEFE;
  width: 48px;
  height: 24px;
  display: flex;
  font-size: 16px;
  background: linear-gradient(87.51deg, #7E0188 0.3%, #400146 101.68%);
  align-items: center;
  font-family: "TT Commons";
  font-weight: 700;
  margin-left: 12px;
  border-radius: 4px;
  flex-direction: row;
  justify-content: center;
}

.chatbot-chatarea{
  width:100%;
  padding:16px;
  display:flex;
  flex-direction:column;
  justify-content:flex-end;
  flex-grow: 1;
}

.chatbot-chatarea>p{
  display: block;
  font-size: 16px;
  font-family: "TT Commons";
  font-weight:400;
  margin-bottom: 16px;
  line-height: 1.38;
}

.chatbot-chatarea ul{
    width:100%;
    display:flex;
    align-items: center;
    justify-content: space-between;
}

.chatbot-chatarea ul li button{
  min-width: 104px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: rgb(232, 238, 250);
  color: rgb(2, 9, 23);
  border: 1px solid rgb(232, 238, 250);
  height: 32px;
    display: flex;
    font-size: 16px;
    font-style: normal;
    align-items: center;
    font-family: TT Commons;
    font-weight: 400;
    line-height: 20px;
    border-radius: 8px;
    letter-spacing: 0.24px;
    text-transform: none;
    justify-content: center;
}

.chatbot-chatarea ul li button:hover{
  background-color: rgb(188, 193, 203);
  color: rgb(2, 9, 23);
}

.chatbot-message{
  width:100%;
  height: 0px;
  background: #B9EAFE;
  border-radius: 12px;
  display:flex;
  align-items:center;
  padding-left: 16px;
  margin-top:0px;
  transition: height 300ms ease;
  overflow: hidden;
}

.chatbot-message.show-message {
  margin-top:16px;
  height:56px;
}

.chatbot-loader{
  margin:0px;
  position: relative;
  cursor:pointer;
  float:left;
  width: 62px;
  height: 0px;
  border-radius:32px;
  display:flex;
  align-items:center;
  justify-content:space-between;
  padding: 0 16px;
  opacity:0;
  overflow:hidden;
  transition: opacity 300ms ease;
  transition: height 300ms ease;
}

.chatbot-loader span{
    display:block;
    height:8px;
    width:8px;
    border-radius:8px;
    background-color:#05112A;
    opacity:0.6;
    animation: bounce 1s infinite ease;
}

.chatbot-loader span.first{
  animation-delay:200ms;
}

.chatbot-loader span.middle{
  animation-delay:400ms;
}
    
.chatbot-loader span.last{
  animation-delay:600ms;
}

.chatbot-loader.show-loader{
   opacity: 1;
   margin:16px auto;
   animation: fadeInLoader 300ms ease;
  animation-fill-mode:forwards;
}

.chatbot-response{
  opacity:0;
  height:0;
  pointer-events:none;
}

.chatbot-response.show-response{
  animation: fadeIn 300ms ease;
  animation-fill-mode:forwards;
  height:auto;
  margin-top:16px;
}

.chatbot-actionsarea{
  width:100%;
  padding: 0 16px 16px 16px;
}

.chatbot-actionsarea input{
  width:100%;
  height: 40px;
  background: #FEFEFE;
  border: 2px solid #BCC1CB;
  border-radius: 4px;
  margin-bottom: 16px;
  padding-left:16px;
  font-size:14px;
}

.chatbot-actions{
  width:100%;
  display:flex;
  justify-content:flex-end;
  margin-bottom:24px;
  background-color:transparent;
}

.chatbot-actions button{
  margin-left:8px;
}

.chatbot-actions button[disabled]{
  opacity: 0.4;
}

.chatbot-actionsarea p, .chatbot-actionsarea p a{
  color: #60646D;
  font-size: 14px;
  font-family: "TT Commons";
  font-weight: 500;
  text-align: center;
}

.chatbot-actionsarea p a{
  text-decoration: underline;
  color: #655591;
}