.playlist-settings{
  // padding:24px;
  border: 1px solid rgb(112, 125, 137);
  width:372px;
  background-color: white;
  border-radius: 8px;
  font-size:14px;
  position: relative;
  top: 170px;
  left: 100px;

  h2{
    font-family: "TT Commons";
    font-size: 16px;
    font-weight: 700;
    color: #05112A;
  }
  .playlist-settings-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
  }
  .container{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #E8EEFA;
      // padding: 18px 0;
      padding: 8px 16px;


      .volume-container, .playback-container{
      height:38px;
      width:38px;
      border-radius:32px;
      // background: #FEFEFE;
      // border: 1px solid #E8EEFA;
      // box-shadow: 0px 1px 5px rgba(28, 34, 46, 0.06);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: width 300ms ease;
      z-index: 2;
      &:focus{
        outline: 0;
        box-shadow: 0 0 0 2px white, 0 0 0 4px $plum;
      }
    }
    .volume-container{
      .volume{
        height:32px;
        width:32px;
        border-radius:32px;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url('../images/icons/volume.svg');
        pointer-events: none;
        &.mute{
          background-image: url('../images/icons/mute.svg');
        }
      }
      .input-container{
        height:100%;
        display:flex;
        align-items: center;
        justify-content: center;
        width: 0;
        position: relative;
        opacity:0;
        pointer-events: none;
        transition: opacity 0s ease 500ms, width 300ms ease;
        input[type='range']{
          display: block;
          -webkit-appearance: none;
          appearance: none;
          width: 96px;
          height: 4px;
          background: transparent;
          border-radius:4px;
          outline: none;
          position:relative;
          z-index: 4;
          &::-webkit-slider-thumb{
            -webkit-appearance: none;
            appearance: none;
            width: 12px;
            height: 12px;
            left: -4px;
            position: relative;
            padding:4px 0;
            background-color: $indigo;
            background-image: url('../images/icons/mini-handle.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: auto;
            border-radius: 4px;
            cursor: pointer;
            display: block;
            border:none;
            transition: all 100ms ease;
            z-index: 5;
          }
          &::-moz-range-thumb {
            width: 10px;
            height: 12px;
            padding:4px 0;
            background-color: $indigo;
            background-image: url('../images/icons/mini-handle.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: auto;
            border-radius: 4px;
            cursor: pointer;
            display: block;
            border:none;
            transition: all 100ms ease;
            z-index: 5;
          }
        }
        &:before{
          content: "";
          position:absolute;
          top:50%;
          left:0;
          transform: translate(0, -50%);
          height:4px;
          width: 96px;
          background: $med-grey;
          border-radius:4px;
          z-index: 3;
        }
        &:after{
          content: "";
          position:absolute;
          top:50%;
          left:0;
          transform: translate(0, -50%);
          width: var(--volume-width);
          height: 5px;
          background-color: $indigo;
          border-radius: 4px;
          z-index: 3;
        }
      }
      &.open{
        width: 144px;
        .input-container{
          opacity:1;
          width: 96px;
          pointer-events: auto;
        }
      }
    }
    .playback-container{
      span{
        font-size:1.6rem;
        font-weight: 700;
        color:$indigo;
        pointer-events: none;
      }
      button{
        display: none;
        background-position: center;
        background-repeat: no-repeat;
        width:16px;
        height:16px;
        opacity:1;
        &.minus{
          background-image:url('../images/icons/mini-minus.svg');
        }
        &.plus{
          background-image:url('../images/icons/mini-plus.svg');
        }
        &.hidden{
          opacity:0;
        }
      }
      &.open{
        width: 144px;
        padding: 0 6px;
        justify-content: space-between;
        button{
          display: block;
        }
      }
    }
  }
}
