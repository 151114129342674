.display-settings{
  padding: 0 24px;
  padding-bottom: 100px;
  margin-top: 12px;
  margin-left: 16px;
  //color mode modifications
  &.light{
    .category{
      .label,p{
        color:$indigo
      }
    }
  }
  &.dark {
    .category {
      .label, p {
        color: $tinted-white
      }

      &.text-scale .input-container {
        .littleA {
          background-image: url("../images/icons/littleA-white.svg")
        }

        .bigA {
          background-image: url("../images/icons/bigA-white.svg")
        }
      }

      &.font-options .input-container {
        ul li {
          color:white;
        }
      }
    }
  }
  .category{
    margin-top:16px;
    padding-bottom:16px;
    &:not(:last-child){
      border-bottom: 1px solid rgba(155, 161, 172, 0.2);
    }
    p{
      @include h6;
    }
    .input-container{
      display:flex;
    }
    input:checked{
      &+label .label{
        font-weight:600;
      }
    }
    .label{
      @include p;
      font-size:1.4rem;
      font-weight:400;
      margin:8px 0;
      text-align: center;
      cursor:pointer;
      width: 100%;
      display: block;
    }

    //applies to color mode only
    &.color-mode{
      &>div{
        display:flex;
      }
      .input-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        button{
          width: 110px;
          height: 53px;
          border-radius: 10px;
          padding: 2px 2px 2px 6px;
          position: relative;
          overflow: hidden;
          text-align:left;
          &.light{
            border: 2px solid $tinted-white;
            background-color: $light;
            &.active{
              border: 3px solid #6D0176;
            }
          }
          &.sepia{
            border: 2px solid $sepia;
            background-color:$sepia;
            margin: 0 8px;
            &.active{
              border: 3px solid #E9E3BD;
            }
          }
          &.dark{
            border: 2px solid $dark;
            background-color:$dark;
            span{
              color:white;
            }
            &.active{
              border: 3px solid $tinted-white;
            }
          }
          &:not(:last-of-type){
            margin-right:8px;
          }
          span{
            color:$dark;
            font-family: serif;
            line-height: 16px;
            font-size: 1.8rem;
            hyphens: none;
            display: block;
            text-align: center;
          }
        }
        label.active{
          font-weight: 600;
        }
      }
    }

    //applies to text scale only
    &.text-scale{
      .input-container{
        .littleA, .bigA{
          width:24px;
          height:24px;
          display:block;
          background-position: center;
          background-repeat: no-repeat;
          margin-top: 11px;
        }
        .littleA{
          background-image: url("../images/icons/littleA.svg");
        }
        .bigA{
          background-image: url("../images/icons/bigA.svg");
        }
        .MuiSlider-root{
          width:85%;
          margin: 10px;
        }
        .MuiSlider-rail{
          opacity: 1;
          height:2px;
          background-color:$grey3;
        }
        .MuiSlider-track{
          width:100%;
          height:2px;
          background-color:$grey3;
        }
        .MuiSlider-thumb {
          background: #6D0176;
          &:hover,&.Mui-focusVisible{
            box-shadow: 0px 0px 0px 8px rgba(4, 122, 156, 0.16);
          }
          &.MuiSlider-active{
            box-shadow: 0px 0px 0px 14px rgba(4, 122, 156, 0.16);
          }
        }
      }
    }

    //applies to font options only
    // &.font-options .input-container{
    //   ul{
    //     margin-left: 0;
    //     margin-bottom: 0;
    //     li{
    //       display: flex;
    //       margin: 0;
    //       @include p;
    //       //margin:16px 0;
    //       cursor:pointer;
    //       &.default{
    //         font-family: "sans-serif";
    //       }
    //       &.verdana{
    //         font-family: "sans-serif";
    //       }
    //       &.arial{
    //         font-family: "sans-serif";
    //       }
    //       &.times{
    //         font-family: "sans-serif";
    //       }
    //       &.sassoon{
    //         font-family: "sans-serif";
    //       }
    //       &:before{
    //         content:'';
    //         display: block;
    //         width:24px;
    //         height:24px;
    //         margin-right:8px;
    //         background-position: center;
    //         background-repeat:no-repeat;
    //         background-image:url('../images/icons/check-green.svg');
    //         opacity:0;
    //       }
    //       &.active{
    //         color:#00796D;
    //         &:before{
    //           opacity:1;
    //         }
    //       }
    //     }
    //   }
    // }

    &.character-case .input-container, &.line-spacing .input-container, &.page-progression .input-container{
      justify-content: space-between;
      background-color:$light-grey;
      border:1px solid #D5DBE7;
      height:40px;
      border-radius: 40px;
      padding: 3px 4px 4px 4px;
      input:checked{
        &+label{
          background-color:$tinted-white;
          border:1px solid #D5DBE7;
          span{
            font-weight:600;
            color:#6D0176;
          }
        }
      }
      label{
        width:50%;
        height:32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius:40px;
        cursor: pointer;
        span{
          @include p;
        }
      }
    }


    //applies to show notes only
    &.show-highlights {
      label {
        font-size: 1.6rem;
        font-weight: 400;
      }

      .toggle-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
      }
    }

    //applies to the the radio options (not text scale or font options)
    &.color-mode, &.character-case, &.line-spacing, &.highlight-colors, &.page-progression{
      p{
        margin-bottom:8px;
      }
      .input-container input{
        opacity: 0;
        width:0;
        height:0;
        margin-left:-3px;
      }
    }
  }
}
