.reader-header{
    height: 56px;
    width:100%;
    background-color: $tinted-white;
    /* border-bottom: 1px solid #DDE3EE; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    position: relative;
    display: flex;
      align-items: center;
      justify-content: flex-end;
  }
  
  .reader-header .chapter{
    font-family: "TT Commons";
      font-size: 18px;
      font-weight: 700;
      font-style: normal;
      color: #1C222E;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: fit-content;
      max-width: 430px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0;
  }

  .reader-header .avatar-button{
    margin-left:16px;
    display: flex;
    align-items: center;
    &.active{
        .avatar{
            color:white;
            background: linear-gradient(135deg, #EE512E 0%, #DF006B 78.12%, #BD0071 100%);
        }
        .arrow{
            transform:rotate(180deg)
        }
    }
  }
  
  .reader-header .avatar{
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: 1px solid #BCC1CB;
    color: #020917;
    display: flex;
    align-items: center;
    justify-content: center;
    // font-size: 12px;
    // font-weight:700;
    font-family: "TT Commons";
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 110%;
    background: #F7F9FD;
    border: 1px solid #E8EEFA;
    margin-right:4px;
  }

.carousel{
    // background-color:$tinted-white;
    height:calc(100% - 120px);
    /* width:calc(100% - 50px); */
    margin:0;
    /* margin-left: 50px; */
    display: flex;
    position: relative;
    overflow-x: hidden;
    overflow-y:hidden;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &.cleanmode{
        height:calc(100% - 64px);
    }
}

.carousel::-webkit-scrollbar, .carousel li.slide::-webkit-scrollbar {
    display: none;
}

.carousel  li.slide{
    position:relative;
    height:calc(100vh - 62px);
    min-width: 100%;
    // padding: 80px 20px 160px 60px;
    scroll-snap-align: center;
    scroll-snap-stop: always;
    scroll-padding: 16px;
    overflow-y: scroll;
    overflow-x:hidden;
    font-size:1.3rem;
}

.carousel li.slide p{
    font-size: 1.3rem;
    line-height: 200%;
    text-transform: none;
}

footer{
    // background-color: #F7F9FD;
    background-color:$tinted-white;
    box-shadow: 1px -4px 10px 0px rgba(221, 227, 238, 0.64);
    height:64px;
    // width:calc(100% + 86px);
    width:100%;
    position: relative;
    
    
    transition: margin 300ms ease;
    position: absolute;
    bottom: 0;
    left: 0;

    .inner-wrapper{
        padding: 0 12px;
        width:100%;
        height:64px;
        display:flex;
        align-items: center;
        justify-content: space-between;
        @media(max-width: 749px){
            overflow-x: auto;
         }
    }

    .white-sliver{
        width: 100%;
        height: 1.5px;
        background-color: white;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10000;
    }

    .go-to-page{
        display: flex;
        align-items: center;
        justify-content: space-between;
        // width:298px;
        gap:12px;
        height: 100%;
        position:absolute;
        top:50%;
        left:50%;
        transform: translate(-50%, -50%);
        @media(max-width:870px){
            position: unset;
            transform: unset;
        }
        .go-to-page-container{
            display: flex;
            gap:4px;
            align-items: center;
        }
        button{
            height: 25px;
            width: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .input-container{
            width: 72px;
            height: 40px;
            background: #FEFEFE;
            border: 1px solid #BCC1CB;
            border-radius: 4px;
            // margin: 0 8px;
            // padding-left: 16px;
            display:flex;
            align-items: center;
            justify-content: center;
            position:relative;
    
            input{
                width:80%;
                padding:8px;
                background:transparent;
                border:none;
                font-size: 16px;
                text-align: center;
            }
            .bookmark-button{
                position:absolute;
                right:8px;
    
            }
        }
        span{
            font-size:18px;
            font-weight: 400;
            // margin: 0 8px;
            font-size: 16px;
            color:#60646D;
            display: block;
            white-space:nowrap;
         }
    }

    .zen-toggle{
        display: flex;
        align-items: center;
        margin-left:4px;
        .toggle{
            border: 0.5px solid #020917 !important;
        }
        .toggle.on {
            background: rgb(5, 17, 42) !important;
        }
        label{
            margin-right:8px;
            font-family: 'TT Commons';
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
        }
    }
}
   