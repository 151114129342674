// .channel-card{
//     border: 1px solid rgb(232, 238, 250);
//     padding: 16px 10px;
//     background: linear-gradient(54.54deg, rgb(5, 17, 42) 38.55%, rgba(5, 17, 42, 0) 77.87%), linear-gradient(rgb(114, 35, 81) 0%, rgba(114, 35, 81, 0) 100%);
//     margin:16px;
//     border-radius: 8px;
//     p{
//         color: white;
//         text-align: center;
//         &.title{
//             font-size: 22px;
//             font-weight:700;
//             margin:0;
//         }
//     }
//     .images{
//         display:flex;
//         align-items: center;
//         justify-content: space-between;
//         width: 90%;
//         margin: 0 auto 16px auto;
//         img{
//             border-radius: 6px;
//         }
//     }
// }

.channels{
    padding: 0 16px 0 20px;
    overflow-y: scroll;
    height: calc(100vh - 183px);

    .primary{
        width: 190px;
        height: 32px;
        margin:20px auto 34px auto;
        display:block;
        font-size: 14px;
    }

    .video-card{
        background: rgb(254, 254, 254);
        border-color: rgb(188, 193, 203);
        border-width: 1px;
        border-style: solid;
        border-radius: 6px;
        width: 100%;
        min-height: 104px;
        padding: 12px 16px;
        margin: 0px;
        overflow: hidden;
        cursor: pointer;
        transition: border 0.25s ease 0s;
        margin-bottom:16px;
        &:hover, &.active{
            border-color: rgb(204, 1, 112);
        }
        .card-header{
            display:flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom:8px;
            p{
                margin:0;
                font-weight:bold;
                font-size: 14px;
            }
            div{
                display:flex;
                align-items: center;
                justify-content: space-between;
                gap:4px;
            }
        }
        img{
            width:100%;
        }
    }


    .fake-select{
        margin: 16px 16px 0 16px;
        width:calc(100% - 32px);
    }
    h3{
        font-size:16px;
        line-height:24px;
        margin:16px;    

    }
    .featured-video-card{
        width: 100%;
        position: relative;
        border-radius: 8px; 
        video{
            width: calc(100% + 34px);
            margin-left:-17px;
        }
        .title-header{
            display:flex;
            align-items: center;
            gap:16px;
        }
        .tutor-img{
            width: 32px;
            height: 32px;
            border-radius: 100%;
            border: 1px solid rgb(220, 220, 220);
            object-fit: cover;
        }
        p{
            margin: 0;
            &.title{
                font-family: "TT Commons";
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 109.5%;
                color: rgb(21, 21, 21);
                margin: 0px;
            }
            &.meta{
                color: rgb(0, 0, 0);
                font-family: "TT Commons";
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0.24px;
            }
        }
    }
    // .video-card{
    //     border-radius: 8px;
    //     border: 1px solid #BCC1CB;
    //     background: #FEFEFE;
    //     display: flex;
    //     align-items: center;
    //     height: 100px;
    //     margin:0 16px 16px 16px;
    //     cursor:pointer;
    //     &:hover{
    //         border: 1px solid #E20D63;
    //     }
    //     .image-container{
    //         border-radius: 8px 0px 0px 8px;
    //         width: 100px;
    //         height:100%;
    //         overflow:hidden;
    //         margin-right:16px;
    //         flex-grow:0;
    //         flex-shrink:0;
    //         img{
    //             object-fit: cover;
    //             object-position: center;
    //             width: 100px;
    //             height:100%;
    //         }
    //     }   
    //     p{
    //         margin: 0;
    //         span{
    //             margin: 0 4px;
    //             display:inline-flex;
    //             align-items: center;
    //             img{
    //                 margin: 0 4px 2px 4px;
    //             }
    //         }
    //     } 
          
    // }
} 