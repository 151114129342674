.audio-container{
  display: flex;
  align-items: center;

  .timestamp{
    color: var(--text-light-theme-muted, #60646D);
    text-align:left;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: TT Commons;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.24px;
    margin-right:8px;
    width: 70px;
  }

  .audio-button{
    // display: flex;
    // align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    margin: 2px 0px;
    flex-shrink: 0;
    background-image: url('../images/toolbar/audio.svg');
    &.active{
      background-image: url('../images/toolbar/audio-white.svg'), linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%);
    }
    &.playing{
      background-size: 42px;
      background-image: url('../images/gifs/audio_icon_dark_2024.gif');
    }
    &.active.playing{
      background-size: 42px;
      background-image: url('../images/gifs/audio_icon_2024.gif');
    }
  }
} 
 
.audio-player{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  transition: opacity 200ms ease;
  margin-left:4px;
}
  
.audio-player button{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 25px;
    &.notshowing{
      width:0;
      height:0;
      pointer-events: none;;
    }
    // img{
    //   height: 24px;
    //   width: 24px;
    // }
}

.audio-player button:hover{
  background-color: #F7F9FD;
}

.audio-player>div span{
  font-size:18px;
  font-weight:500;
}

.audio-timeline{
  width:100%;
  height:8px;
  background: #B2B6BF;
  border:none;
  position: absolute;
  bottom:0;
  left:0;
  z-index: 1000;
  pointer-events:none;
  input[type='range']{
      width: 100%;
      height: 8px;
      -webkit-appearance: none;
      appearance: none;
      background:transparent;
      // border-radius:4px;
      outline: none;
      display:block;
      position:relative;
      z-index: 4;
      margin:0;
      &:focus{
        outline:none;
      }
      &::-webkit-slider-thumb{
        -webkit-appearance:none;
        appearance: none;
        // display:inline-block;
        // width:14px;
        // height:14px;
        // background:#F7F9FD;
        // border: 1px solid #bcc1cb;
        // border-radius:100%;
        // position:relative;
        // bottom:8px;
        // cursor:pointer;
      }
      &::-webkit-slider-runnable-track {
        width:100%;
        height: 3px;
        border: none;
        cursor:pointer;
      }
  }
}
