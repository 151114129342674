.scrubber{
    height: 80%;
    position: relative;
    width: 86px;
    position: absolute;
    top: 120px;
    left: 0;
    z-index: 1000;
    margin-left:16px;


    .MuiSlider-root.MuiSlider-vertical{
        padding:0;
        margin: 0 auto;
        display: block;
    }

    .MuiSlider-rail{
        opacity: 1 !important;
        width: 4px !important;
        background-color:#5F636E !important;
        border-radius: 4px;
    }

    .MuiSlider-track{
        opacity: 1 !important;
        width: 4px !important;
        background-color: #9BA1AD !important;
    }

    .MuiSlider-thumb {
        width: 12px !important;;
        height: 19px !important;;
        margin-left:-4px !important;
        padding:4px 0 !important;;
        background-color: #161C29 !important;
        // background-color: #005A70 !important;
        background-image: url('../images/scrubber/handle-rotated.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto;
        border-radius: 4px !important;;
        border:none;
        z-index:1000;
    }

    &.bookmark-active .MuiSlider-thumb {
        background-color: #005A70 !important;
    }

    .MuiSlider-thumb:hover,.MuiSlider-thumb.Mui-focusVisible{
        /* box-shadow: 0px 0px 0px 6px rgba(0,0,0, 0.16); */
        // box-shadow: none !important;
        box-shadow: 0px 0px 0px 8px rgba(0, 93, 131, 0.16) !important;;
    }

    .MuiSlider-thumb.MuiSlider-active{
        /* box-shadow: 0px 0px 0px 6px rgba(0,0,0, 0.16); */
        // box-shadow: none !important;
        box-shadow: 0px 0px 0px 14px rgba(0, 93, 131, 0.16) !important;;
    }

    .bookmarks{
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 2;
        pointer-events: none;
    }

    .bookmark{
        width: 8px;
        height: 8px;
        left: 40px;
        display: block;
        margin:0 auto;
        z-index:999;
        position: absolute;
        pointer-events: auto;
        transition-delay: 300ms;
        //background: linear-gradient(135deg, #EE512E 0%, #DF006B 63.44%, #BD0071 100%);
        //   background-color: #005A70;
        background-color: #005A70;
        border: 1px solid #FEFEFE;
        border-radius: 24px;
        cursor:pointer;
    }

    .history{
        height: 16px;
        width: 16px;
        position: absolute;
        left: 35.5px;
        bottom: 0;
        z-index: 2;
        fill: #05112A;
        cursor:pointer;
        circle{
            fill: #F7F9FD !important;
        }
    }

    .history.in-motion{
        fill: #9BA1AD;
    }

    .history.bookmark-active{
        fill: #F7F9FD !important;
        circle{
            fill: rgb(0, 101, 91) !important;
        } 
    }
}
